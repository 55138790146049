import { createApp } from 'vue';
import App from './App.vue';

import ElementUI from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import router from './router';
import i18n from './locales';
import SvgLeft from './assets/SvgLeft.vue';
import SvgRight from './assets/SvgRight.vue';
import SvgThank from './assets/SvgThank.vue';

var app = createApp(App);

// 图标
app.component('SvgLeft', SvgLeft);
app.component('SvgRight', SvgRight);
app.component('SvgThank', SvgThank);

app.use(router);
app.use(i18n);
app.use(ElementUI);
app.mount('#app');

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
