<template>
    <router-view />
</template>

<style>
body {
    background-color: black;
    font-weight: 700;
    font-family: "Inter";
    color: white;
}
</style>

<script>
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};

export default {
    name: 'main',
    components: {},

    mounted() {        
        
        var url = decodeURI(window.location.href);

        // 根据不同路由跳转不同页面
        if (this._isMobile()) {
            console.log('手机端')
            this.$router.path = ''
            this.$router.replace('/mobile' + window.location.pathname)
        } else {
            console.log('pc端')
            // console.log(this.$router)
            this.$router.replace('/computer' + window.location.pathname)
        }

        // console.log(window.location)
    },
    methods: {
        // 判断是否是手机端，如果是，返回true
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        },
    }

    
}
</script>