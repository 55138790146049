<template>
    <div class="page">
        <div class="page_content">
            <div class="booking_title"> Booking Modification </div>

            <div class="booking_info" v-if="isRefund">
                <div class="book_info_title"> Booking Information: </div>
                <div class="book_info_date"> {{ bookData }} </div>
                <div class="book_info_time"> {{ bookTime }} </div>
            </div>

            <div v-if="isRefund">
                <div class="booking_message"> If you would like to update your booking, please cancel your current
                    booking and make a new booking with us. Sorry for the inconvenience. </div>

                <div class="refund_dialog">
                    <div class="refund_dialog_title"> Are you sure you want to cancel your booking? </div>

                    <div class="button_bar">
                        <el-button class="button_no" @click="cancel()">
                            {{ $t('button.no') }}
                        </el-button>
                        <el-button class="button_yes" @click="refund()">
                            {{ $t('button.yes') }}
                        </el-button>
                    </div>
                </div>

                <div class="refund_dialog_remark"> Note: Booking modification can only be made at least 24 hours before
                    the scheduled booking time. More details
                    <a href="https://www.runninggrid.games/cancellation-and-refund-policy" target="_blank"> here </a>.
                </div>
            </div>

            <div v-if="noRefund">
                <div class="booking_message"> Sorry, you cannot modify your booking within 24 hours before your booking
                    time. </div>

                <div class="refund_dialog_remark">
                    <div>More details
                        <a href="https://www.runninggrid.games/cancellation-and-refund-policy" target="_blank"> here
                        </a>.
                    </div>

                    <div> If you have any other enquires, feel free to
                        <a href="https://www.runninggrid.games/contact" target="_blank"> contact us </a>.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import $http from '../../http/index.js';
// const host = 'http://192.168.8.34:8080/manage';
const host = '';

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            id: '',
            bookData: 'Sunday, 03 December 2023',
            bookTime: '8:00 AM - 8:30 AM',

            isRefund: false,
            noRefund: false,
        };
    },
    unmounted() {
    },
    mounted() {
        this.id = this.getRequest('id');
        this.getBook(this.id);
    },
    methods: {

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true })
        },

        getRequest(value) {
            var url = decodeURI(window.location.href);
            var object = {};
            if (url.indexOf("?") != -1) {
                var str = url.substring(url.indexOf("?") + 1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    object[strs[i].split("=")[0]] = strs[i].split("=")[1];
                }
            }
            return object[value];
        },

        /*************** 公共或系统方法 ***************/

        // 获得预约日期的可预约时间列表
        getBook(id) {
            $http.post(host + '/book/queryById', { id: id, })
                .then(res => {
                    if (res.code == 0) {
                        this.bookData = dayjs(res.data.bookDate).format('dddd, DD MMMM YYYY');
                        this.bookTime = dayjs(this.bookData + ' ' + res.data.bookTimeBegin + ':00').format('HH:mm A') + ' - ' +
                            dayjs(this.bookData + ' ' + res.data.bookTimeEnd + ':00').format('HH:mm A');

                        if (res.data.intervalHours > 24) {

                            // 间隔>24，可以改预约
                            this.isRefund = true;
                            this.noRefund = false;
                        } else {

                            // 间隔<=24，不能改预约
                            this.isRefund = false;
                            this.noRefund = true;
                        }
                    } else {
                        this.error(this.$t('public.error.execute'));
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        cancel() {
            location.replace("https://www.runninggrid.games");
        },

        // 退款
        refund() {
            $http.post(host + '/book/refundOrder', { id: this.id, })
                .then(res => {
                    if (res.code == 0) {
                        location.replace("https://www.runninggrid.games/refund-success");
                    } else {
                        location.replace("https://www.runninggrid.games/refund-failure");
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        }
    }
};
</script>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    height: calc(100% - 20px);
    padding: 20px 20px 0px 20px;
}

.booking_title {
    color: white;
    font-size: 26px;
    line-height: 60px;
    margin-left: 20px;
}

.booking_info {
    background-color: #E0EFC8;
    padding: 10px 20px 10px 20px;
}

.book_info_title {
    font-weight: 700;
    font-size: 20px;
    color: black;
    line-height: 40px;
}

.book_info_date {
    font-weight: 400;
    font-size: 20px;
    color: black;
    line-height: 40px;
}

.book_info_time {
    font-weight: 400;
    font-size: 20px;
    color: black;
    line-height: 40px;
}

.booking_message {
    font-weight: 200;
    font-size: 20px;
    color: white;
    margin: 18px;
}

.refund_dialog {
    border: 1px solid #939393;
    margin: 20px;
    border-radius: 10px;
    padding: 20px
}

.refund_dialog_title {
    font-weight: 700;
    font-size: 18px;
    color: white;
    line-height: 30px;
}

.button_bar {
    margin-top: 15px;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
}

.button_yes {
    width: 120px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.button_no {
    width: 120px;
    height: 35px;
    background-color: black;
    border-radius: 7px;
    border-width: 3px;
    border-color: #e0efc8;
    font-weight: 700;
    font-size: 15px;
    color: white;
}

.refund_dialog_remark {
    font-weight: 200;
    font-size: 18px;
    color: white;
    margin: 20px;
}
</style>
