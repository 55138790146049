import { createRouter, createWebHashHistory } from 'vue-router';
import computerBook from '../views/computer/book.vue';
import computerRefund from '../views/computer/refund.vue';
import computerRanking from '../views/computer/ranking.vue';
import mobileBook from '../views/mobile/book.vue';
import mobileRefund from '../views/mobile/refund.vue';
import mobileRanking from '../views/mobile/ranking.vue';

const routes = [
    {
        path: '/computer/book/',
        name: 'computerBook',
        component: computerBook
    },
    {
        path: '/computer/refund/',
        name: 'computerRefund',
        component: computerRefund
    },
    {
        path: '/computer/ranking/',
        name: 'computerRanking',
        component: computerRanking
    },
    {
        path: '/mobile/book/',
        name: 'mobileBook',
        component: mobileBook
    },
    {
        path: '/mobile/refund/',
        name: 'mobileRefund',
        component: mobileRefund
    },
    {
        path: '/mobile/ranking/',
        name: 'mobileRanking',
        component: mobileRanking
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
