<template>
    <div class="page">
        <div>
            <a href="https://www.runninggrid.games" target="_blank">
                <img src="img/logo2.png" style="height: 40px; margin-bottom: 20px;" />
            </a>

            <el-steps :active="active" finish-status="success" style="margin-top: 20px; display: none">
                <el-step :title="$t('room.title.page')" />
                <el-step :title="$t('time.title.page')" />
                <el-step :title="$t('people.title.page')" />
                <el-step :title="$t('name.title.page')" />
                <el-step :title="$t('price.title.page')" />
                <el-step :title="$t('pay.title.page')" />
                <el-step :title="$t('thank.title.page')" />
            </el-steps>
        </div>

        <!-- 选择房间 -->
        <div v-show="active == 0">
            <div class="store_suject">
                Please select gaming room.
            </div>

            <div v-for="store in rooms" :key="store.storeId" class="store_div">
                <span class="store_title">{{ store.storeName }}</span>

                <div class="room_list">
                    <div v-for="room in store.rooms" :key="room.roomId" class="room_div">
                        <el-image :class="room.imageUrl ? 'room_image' : 'room_image_no'" :src="room.imageUrl"
                            fit="contain" />
                        <span class="room_remark" v-if="room.remark">{{ room.remark }}</span>

                        <el-button class="room_select" @click="selectRoom(room)">
                            {{ $t('button.select') }}
                        </el-button>
                    </div>
                </div>
            </div>

            <div style="padding-bottom: 30px;"></div>
        </div>

        <!-- 选择预约时段 -->
        <div v-show="active == 1" style="width: 100%">
            <div class="info_bar">
                For larger group bookings (more than {{ maximum }} people) or specific requirements, please
                <a href="https://www.runninggrid.games/contact" target="_blank"> contact us</a>.
            </div>

            <div class="calendar_room">
                Booking for {{ form.storeName }} - {{ form.roomName }}
            </div>

            <div style="display: flex; flex-direction: column; height: 100%">
                <el-row align="middle">
                    <span class="calendar_title">{{ $t('time.title.calendar') }}</span>
                    <el-date-picker style="margin-left: 10px; flex: 1 1 auto" format="dddd, DD MMMM YYYY" v-model="date"
                        type="date" popper-class="popperClass" @change="getBookTimes()" />
                </el-row>

                <span style="margin-top: 20px">Select / Multi-select Session(s)</span>

                <el-divider class="calendar_content_space" />

                <div class="table_content">
                    <el-table :data="tableData" :height="tableHeight" :cell-style="cellStyle" :show-header="false"
                        empty-text=" ">
                        <el-table-column prop="time" label="Time" />
                        <el-table-column prop="operate" label="Operate" align="center" width="180">
                            <template #default="scope">
                                <el-checkbox-button class="table_button" color="#e0efc8" :disabled="!scope.row.enabled"
                                    v-model="scope.row.selected">
                                    {{ scope.row.enabled ? $t('time.button.select') : 'Unavailable' }}
                                </el-checkbox-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="button_bar">
                    <el-button class="button_prev" @click="active = 0">
                        {{ $t('button.prev') }}
                    </el-button>
                    <el-button class="button_next" @click="checkBookTimes()">
                        {{ $t('button.next') }}
                    </el-button>
                </div>
            </div>
        </div>

        <!-- 选择参与人数 -->
        <div v-show="active == 2" class="div_center">
            <el-form :model="form" :rules="rules" ref="dialogForm" label-position="top">
                <div class="people_title">Booking on {{ getTableTitle() }} <br /> {{ form.storeName }} - {{ form.roomName }}</div>

                <div class="otherHeight">
                    <el-form-item class="people_item" :label="$t('people.label.adult')" prop="adult">
                        <div>
                            <el-button class="button_number" @click="adultCalc(-1)">－</el-button>
                            <el-select v-model="people.adult" class="input_number" @change="adultChange">
                                <el-option v-for='item in adults' :value='item.value' :key='item.value'
                                    :label='item.label' />
                            </el-select>
                            <el-button class="button_number" @click="adultCalc(1)">＋</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item class="people_item" prop="child">
                        <template #label>
                            <div style="display: block;">
                                {{ $t('people.label.child') }}
                                <div style="font-size: 12px;color: red; margin-top: -5px;">
                                    (Must be supervised by an adult)
                                </div>
                            </div>
                        </template>

                        <el-button class="button_number" @click="childCalc(-1)">－</el-button>
                        <el-select v-model="people.child" class="input_number" @change="childChange">
                            <el-option v-for='item in childs' :value='item.value' :key='item.value'
                                :label='item.label' />
                        </el-select>
                        <el-button class="button_number" @click="childCalc(1)">＋</el-button>
                    </el-form-item>
                    <el-form-item class="people_item" prop="student">
                        <template #label>
                            <div style="display: block;">
                                {{ $t('people.label.student') }}
                                <div style="font-size: 12px;color: red; margin-top: -5px;">
                                    (must hold valid student ID)
                                </div>
                            </div>
                        </template>

                        <el-button class="button_number" @click="studentCalc(-1)">－</el-button>
                        <el-select v-model="people.student" class="input_number" @change="studentChange">
                            <el-option v-for='item in students' :value='item.value' :key='item.value'
                                :label='item.label' />
                        </el-select>
                        <el-button class="button_number" @click="studentCalc(1)">＋</el-button>
                    </el-form-item>

                    <div style="margin-top: 30px">{{ $t('people.label.common1') }}</div>
                    <div>{{ $t('people.label.common2') }} {{ maximum }}</div>
                    <div>{{ $t('people.label.common3') }} {{ weekend }} ({{ workday }} {{ $t('people.label.common4') }})
                    </div>
                </div>

                <div class="button_bar">
                    <el-button class="button_prev" @click="active = 1">
                        {{ $t('button.prev') }}
                    </el-button>
                    <el-button class="button_next" @click="checkPeopleNumber()">
                        {{ $t('button.next') }}
                    </el-button>
                </div>
            </el-form>
        </div>

        <!-- 输入个人信息 -->
        <div v-show="active == 3" class="div_center">
            <el-form :model="form" :rules="rules" ref="dialogForm" label-position="top">
                <div class="name_title">Booking on {{ getTableTitle() }} <br /> {{ form.storeName }} - {{ form.roomName }}</div>

                <div class="otherHeight">
                    <el-form-item class="name_item" :label="$t('name.label.firstName')" prop="firstName">
                        <el-input v-model="form.firstName" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.lastName')" prop="lastName">
                        <el-input v-model="form.lastName" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.email')" prop="email">
                        <el-input v-model="form.email" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.reEmail')" prop="reEmail">
                        <el-input v-model="form.reEmail" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.mobile')" prop="mobile">
                        <el-input v-model="form.mobile" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.teamName')" prop="teamName">
                        <el-input v-model="form.teamName" clearable></el-input>
                    </el-form-item>

                    <div style="margin-top: 30px; font-size: 15px; font-weight: 100">
                        <span class="must_field">*</span>
                        {{ $t('name.label.common1') }}
                    </div>
                    <div style="margin-top: 10px; font-size: 15px; font-weight: 100">
                        {{ $t('name.label.common2') }}
                    </div>

                    <div style="margin-top: 30px">
                        <el-checkbox v-model="form.promote">
                            <span class="overflow">{{ $t('name.label.checkbox1') }}</span>
                        </el-checkbox>
                    </div>
                    <div style="margin-top: 30px">
                        <el-checkbox v-model="form.agreement">
                            <span class="overflow">
                                {{ $t('name.label.checkbox2') }}
                                <a href="https://www.runninggrid.games/activity-participation-agreement-and-waiver"
                                    target="_blank">{{ $t('name.label.checkbox3') }}</a>
                            </span>
                        </el-checkbox>
                    </div>
                </div>

                <div class="button_bar">
                    <el-button class="button_prev" @click="active = 2">
                        {{ $t('button.prev') }}
                    </el-button>
                    <el-button class="button_next" @click="checkEmailTeam()">
                        {{ $t('button.next') }}
                    </el-button>
                </div>
            </el-form>
        </div>

        <!-- 显示订单价格 -->
        <div v-show="active == 4">
            <div class="price_title">Summary</div>

            <div class="otherHeight">
                <div>
                    <div class="price_booking_title_top">Booking on {{ getTableTitle() }}</div>
                    <div class="price_booking_title">{{ form.timeScope }}</div>
                    <div class="price_booking_title_bottom">{{ form.storeName }} - {{ form.roomName }}</div>
                </div>

                <el-divider style="margin-top: 20px; margin-bottom: 20px" />

                <el-row style="margin-top: 20px" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div>Number of players</div>
                    </el-col>
                    <el-col :span="9">
                        <div>Unit Price</div>
                    </el-col>
                </el-row>

                <el-divider style="margin-top: 10px; margin-bottom: 0px" />

                <el-row style="margin-top: 10px; margin-bottom: 10px" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div v-if="form.adult > 0" class="price_detail">{{ form.adult }} Adults</div>
                        <div v-if="form.child > 0" class="price_detail">{{ form.child }} Children (13 & under)</div>
                        <div v-if="form.student > 0" class="price_detail">{{ form.student }} Students</div>
                    </el-col>
                    <el-col :span="9">
                        <div v-if="form.adult > 0" class="price_detail">NZ${{ form.adultPrice }}</div>
                        <div v-if="form.child > 0" class="price_detail">NZ${{ form.childPrice }}</div>
                        <div v-if="form.student > 0" class="price_detail">NZ${{ form.studentPrice }}</div>
                    </el-col>
                </el-row>

                <el-divider style="margin-top: 0; margin-bottom: 0px" />

                <el-row style="margin-top: 10px; margin-bottom: 10px" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div class="price_detail">Number of session(s)</div>
                    </el-col>
                    <el-col :span="9">
                        <div class="price_detail">{{ form.sessionNumber }}</div>
                    </el-col>
                </el-row>

                <el-divider style="margin-top: 0; margin-bottom: 0px" />

                <el-row style="margin-top: 10px; margin-bottom: 10px" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div class="price_detail">Subtotal</div>
                        <div class="price_detail">Discount</div>
                        <div class="price_detail" v-if="form.promoValue > 0"> Promo Code </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="price_detail">NZ${{ form.totalPrice }}</div>
                        <div class="price_detail">NZ${{ form.discount }}</div>
                        <div class="price_detail" v-if="form.promoValue > 0"> NZ${{ form.promoValue }} </div>
                    </el-col>
                </el-row>

                <el-row style="margin-bottom: 10px" align="middle" v-if="form.promoReason">
                    <el-col :span="1"></el-col>
                    <el-col :span="23">
                        <div class="price_promo">{{ form.promoReason }}</div>
                    </el-col>
                </el-row>

                <el-divider style="margin-top: 0; margin-bottom: 0px" />

                <el-row class="price_total" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div>Total</div>
                    </el-col>
                    <el-col :span="9" class="price_total_title">
                        <div>NZ${{ form.dealPrice }}</div>
                    </el-col>
                </el-row>

                <el-row class="price_total" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="23">
                        <span>Promo Code</span>
                        <el-input v-model="form.promoCode" style="width: 35%; margin-left: 10px; margin-right: 10px;"
                            clearable></el-input>
                        <el-button class="button_verify" @click="getPayment()">
                            {{ $t('button.verify') }}
                        </el-button>
                    </el-col>
                </el-row>

                <el-row align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="23">
                        <div style="font-size: 14px; font-weight: 100; margin-top: 10px">The following taxes are
                            included in the
                            price:</div>
                    </el-col>
                </el-row>

                <el-row style="font-size: 14px; font-weight: 100; margin-top: 10px" align="middle">
                    <el-col :span="1"></el-col>
                    <el-col :span="14">
                        <div>Included GST:</div>
                    </el-col>
                    <el-col :span="9">
                        <div>NZ${{ form.gst }}</div>
                    </el-col>
                </el-row>

                <el-divider style="margin-top: 10px; margin-bottom: 20px" />
            </div>

            <div class="button_bar">
                <el-button class="button_prev" @click="active = 3">
                    {{ $t('button.prev') }}
                </el-button>
                <el-button class="button_next" @click="toSubmitOrder()">
                    {{ $t('button.next') }}
                </el-button>
            </div>
        </div>

        <!-- 付款 -->
        <div v-show="active == 5">
            <div class="price_title">Payment</div>

            <div class="otherHeight">
                <div>
                    <div class="price_booking_title_top">Booking on {{ getTableTitle() }}</div>
                    <div class="price_booking_title">{{ form.timeScope }}</div>
                    <div class="price_booking_title_bottom">{{ form.storeName }} - {{ form.roomName }}</div>
                </div>

                <!-- <el-divider style="margin-top: 10px; margin-bottom: 20px;" /> -->

                <div style="margin-top: 20px">Payment Method</div>

                <el-radio-group style="margin-top: 20px" v-model="form.paymentMethod">
                    <el-radio label="PM01" size="large">
                        <img style="width: 120px" src="../../assets/svg/logo-windcave.svg" />
                    </el-radio>
                    <el-radio label="PM02" size="large">
                        <img style="width: 120px" src="../../assets/svg/logo-paypal.svg" />
                    </el-radio>
                </el-radio-group>

                <div style="margin-top: 10px; font-size: 12px">
                    Note: You will be redirected to the payment gateway to enter your card details.
                </div>

                <el-divider style="margin-top: 20px; margin-bottom: 20px" />
            </div>

            <div class="button_bar">
                <el-button class="button_prev" @click="active = 4">
                    {{ $t('button.prev') }}
                </el-button>
                <el-button class="button_next" @click="submitOrder()">
                    {{ $t('button.pay') }}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import $http from '../../http/index.js';
// const host = 'http://127.0.0.1:8080/manage';
const host = '';

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            active: 0,

            rooms: [],
            tableHeight: window.innerHeight - 390,
            otherHeight: window.innerHeight - 242 + 'px',
            tableData: [],

            date: new Date(),

            people: {
                adult: '2',
                child: '0',
                student: '0'
            },

            maximum: 6,
            workday: 2,
            weekend: 3,

            source: {},

            form: {
                bookDate: '',
                bookTime: '',
                roomId: '',
                roomName: '',
                storeName: '',
                playerId: '',
                firstName: '',
                lastName: '',
                email: '',
                reEmail: '',
                mobile: '',
                teamName: '',
                promote: false,
                agreement: false,
                adult: '2',
                child: '0',
                student: '0',
                peopleNumber: '0',
                timeScope: '',
                discount: '0',
                unitPrice: '0',
                totalPrice: '0',
                dealPrice: '0',
                gst: 10,
                paymentMethod: 'PM01',
                promoValue: 0,
                promoCode: '',
            },

            //验证规则
            rules: {
                firstName: [
                    {
                        required: true,
                        message: this.$t('name.check.firstName')
                    }
                ],
                lastName: [
                    {
                        required: true,
                        message: this.$t('name.check.lastName')
                    }
                ],
                email: [
                    {
                        required: true,
                        message: this.$t('name.check.email')
                    }
                ],
                reEmail: [
                    {
                        required: true,
                        message: this.$t('name.check.reEmail')
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: this.$t('name.check.mobile')
                    }
                ],
                teamName: [
                    {
                        required: true,
                        message: this.$t('name.check.teamName')
                    }
                ]
            },

            paymentUrl: '',
            bookId: '',
            sessionId: '',

            adults: [],
            childs: [],
            students: [],
        };
    },
    unmounted() {
        window.removeEventListener('resize', this.getTableHeight);
    },
    mounted() {
        window.addEventListener('resize', this.getTableHeight);
        this.getRooms();
    },
    methods: {
        reset() {
            this.form = {
                bookDate: '',
                bookTime: '',
                roomId: '',
                roomName: '',
                storeName: '',
                playerId: '',
                firstName: '',
                lastName: '',
                email: '',
                reEmail: '',
                mobile: '',
                teamName: '',
                promote: false,
                agreement: false,
                adult: '2',
                child: '0',
                student: '0',
                peopleNumber: '0',
                timeScope: '',
                discount: '0',
                unitPrice: '0',
                totalPrice: '0',
                dealPrice: '0',
                gst: 10,
                paymentMethod: 'PM01',
                sessionNumber: 0,
                promoValue: 0,
                promoCode: '',
            };

            this.people = {
                adult: '2',
                child: '0',
                student: '0'
            };

            this.active = 0;
            this.paymentUrl = '';
            this.bookId = '';
            this.sessionId = '';

            this.adults = [];
            this.childs = [];
            this.students = [];
        },

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true });
        },

        // 获得预约日期内容
        getTableTitle() {
            return dayjs(this.date).format('dddd, DD MMMM YYYY');
        },

        /*************** 公共或系统方法 ***************/

        /*************** 选择预约时间 ***************/

        // 动态改变预约时间列表高度
        getTableHeight() {
            this.tableHeight = window.innerHeight - 390;
        },

        // 预约时间列表单元格样式
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return { 'padding-left': '30px' };
            } else {
                return '';
            }
        },

        // 预约时间日历上一个月份按钮
        prevMonth() {
            this.date = dayjs(this.date).subtract(1, 'month');
            this.getBookTimes();
        },

        // 预约时间日历下一个月份按钮
        nextMonth() {
            this.date = dayjs(this.date).add(1, 'month');
            this.getBookTimes();
        },

        // 选择房间
        selectRoom(room) {
            this.form.roomId = room.roomId;
            this.form.roomName = room.roomName;
            this.form.storeName = room.storeName;

            // 格式化预约日期
            this.form.bookDate = dayjs(this.date).format('YYYY-MM-DD');

            // 获得预约日期的可预约时间列表
            $http.post(host + '/book/queryBookTime',
                {
                    bookDate: this.form.bookDate,
                    roomId: this.form.roomId
                }
            ).then(res => {
                if (res.code == 0) {
                    this.tableData = res.data.times;
                    this.maximum = res.data.room.maximum;
                    this.workday = res.data.room.workday;
                    this.weekend = res.data.room.weekend;

                    this.adults = [];
                    this.childs = [];
                    this.students = [];
                    for (let i = 0; i <= this.maximum; i++) {
                        this.adults.push({ label: i.toString(), value: i.toString() })
                        this.childs.push({ label: i.toString(), value: i.toString() })
                        this.students.push({ label: i.toString(), value: i.toString() })
                    }

                    this.active = 1;
                } else {
                    this.error(this.$t('name.error.getBookTimes'));
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });
        },

        // 获得房间列表
        getRooms() {
            $http
                .get(host + '/book/queryRooms')
                .then(res => {
                    if (res.code == 0) {
                        this.rooms = res.data;
                    } else {
                        this.error(this.$t('name.error.getBookTimes'));
                    }
                })
                .catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        // 获得30分钟时长的可预约时间列表
        getBookTimes() {
            // 格式化预约日期
            this.form.bookDate = dayjs(this.date).format('YYYY-MM-DD');

            // 获得预约日期的可预约时间列表
            $http
                .post(host + '/book/queryBookTime', {
                    bookDate: this.form.bookDate,
                    roomId: this.form.roomId
                })
                .then(res => {
                    if (res.code == 0) {
                        this.tableData = res.data.times;
                        // this.maximum = res.data.room.maximum;
                        // this.workday = res.data.room.workday;
                        // this.weekend = res.data.room.weekend;
                    } else {
                        this.error(this.$t('name.error.getBookTimes'));
                    }
                })
                .catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        // 判断预约时间是否合理
        checkBookTimes() {
            let flag = false;
            let indexTotal = 0,
                indexStart = 0,
                indexEnd = 0,
                indexSelect = 0;
            let bookTimes = [];
            this.tableData.forEach(item => {
                indexTotal++;
                if (item.selected) {
                    indexSelect++;

                    if (indexSelect == 1) {
                        indexStart = indexTotal;
                    }

                    if (indexEnd < indexTotal) {
                        indexEnd = indexTotal;
                    }

                    bookTimes.push(item.time);
                }
            });

            if (indexSelect == indexEnd - indexStart + 1) {
                $http
                    .post(host + '/book/checkBookTime', {
                        bookDate: this.form.bookDate,
                        bookTimes: bookTimes,
                        roomId: this.form.roomId
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.active = 2;
                        } else if (res.code == 4001) {
                            this.error(this.$t('time.error.timeOccupied'));
                        } else if (res.code == 4002) {
                            this.error(this.$t('time.error.timeContinuous'));
                        } else {
                            if (typeof res.info == 'string') {
                                this.error(res.info);
                            } else if (typeof res.info == 'object') {
                                this.error(Object.values(res.info).join('<br/>'));
                            }
                        }
                    })
                    .catch(res => {
                        this.error(this.$t('public.error.network'));
                    });
            } else {
                this.error(this.$t('time.error.timeContinuous'));
            }
        },

        /*************** 选择预约时间 ***************/

        /*************** 选择预约人数 ***************/

        // 判断人数是否合理
        checkPeopleNumber() {
            // 13岁以下，必须有成人陪同
            // if (parseInt(this.people.child) > 0 && parseInt(this.people.adult) <= 0) {
            //     this.error(this.$t('price.error.lackAdult'));
            //     return false;
            // }

            let week = this.date.getDay();
            let total = parseInt(this.people.adult) + parseInt(this.people.child) + parseInt(this.people.student);
            if (total > this.maximum) {
                this.error(this.$t('price.error.limitTotal').replace("6", this.maximum));
                return false;
            }

            if (week == 0 || week == 6) {
                // 周末至少3人以上
                if (total < this.weekend) {
                    this.error(this.$t('price.error.limitWeekend').replace("3", this.weekend));
                    return false;
                }
            } else {
                // 工作日至少2人以上
                if (total < this.workday) {
                    this.error(this.$t('price.error.limitWeekday').replace("2", this.workday));
                    return false;
                }
            }

            this.active = 3;
        },

        // 成人数量列表改变事件
        adultChange(value) {
            this.people.adult = value;
            this.form.adult = this.people.adult;
            // if (this.checkPeopleNumber()) {
            //     this.form.adult = value;
            // } else {
            //     this.people.adult = this.form.adult;
            // }
        },

        // 成人数量加减事件
        adultCalc(value) {
            this.people.adult = (parseInt(this.people.adult) + value).toString();
            if (this.people.adult <= 0) {
                this.people.adult = '0';
            } else if (this.people.adult >= this.maximum) {
                this.people.adult = this.maximum.toString();
            }

            this.form.adult = this.people.adult;
        },

        // 成人数量列表改变事件
        childChange(value) {
            this.people.child = value;
            this.form.child = this.people.child;
        },

        // 幼童数量加减事件
        childCalc(value) {
            this.people.child = (parseInt(this.people.child) + value).toString();
            if (this.people.child <= 0) {
                this.people.child = '0';
            } else if (this.people.child >= this.maximum) {
                this.people.child = this.maximum.toString();
            }

            this.form.child = this.people.child;
        },

        // 学生数量列表改变事件
        studentChange(value) {
            this.people.student = value;
            this.form.student = this.people.student;
        },

        // 学生数量加减事件
        studentCalc(value) {
            this.people.student = (parseInt(this.people.student) + value).toString();
            if (this.people.student <= 0) {
                this.people.student = '0';
            } else if (this.people.student >= this.maximum) {
                this.people.student = this.maximum.toString();
            }

            this.form.student = this.people.student;
        },

        /*************** 选择预约人数 ***************/

        /*************** 选择预约名称 ***************/

        // 根据邮箱和团队，判断玩家是否已经存在
        checkEmailTeam() {
            if (this.form.agreement) {
                if (this.form.email.toLowerCase() == this.form.reEmail.toLowerCase()) {
                    $http
                        .post(host + '/book/checkEmailTeam', {
                            email: this.form.email,
                            teamName: this.form.teamName
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.active = 4;
                                this.getPayment();
                            } else if (res.code == 4000) {
                                this.form.playerId = res.data.id;
                                this.active = 4;
                                this.getPayment();
                            } else if (res.code == 4001) {
                                this.error(this.$t('name.error.checkName'));
                            } else {
                                if (typeof res.info == 'string') {
                                    this.error(res.info);
                                } else if (typeof res.info == 'object') {
                                    this.error(Object.values(res.info).join('<br/>'));
                                }
                            }
                        })
                        .catch(res => {
                            this.error(this.$t('public.error.network'));
                        });
                } else {
                    this.error(this.$t('name.error.checkEmail'));
                }
            } else {
                this.error(this.$t('name.error.agreement'));
            }
        },

        /*************** 选择预约名称 ***************/

        /*************** 显示预约费用 ***************/

        // 查询本次预约的付款信息
        getPayment() {
            let bookTimes = [];
            this.tableData.forEach(item => {
                if (item.selected) {
                    bookTimes.push(item.time);
                }
            });
            this.form.sessionNumber = bookTimes.length;

            $http
                .post(host + '/book/queryPayment', {
                    bookTimes: bookTimes,
                    adult: this.form.adult,
                    child: this.form.child,
                    student: this.form.student,
                    promoCode: this.form.promoCode,
                    roomId: this.form.roomId,
                    email: this.form.email,
                })
                .then(res => {
                    if (res.code == 0) {
                        if (this.form.promoCode && this.form.promoCode != res.data.promoCode) {
                            this.error(this.$t('price.error.promoCode'));
                        }

                        this.form.timeScope = res.data.timeScope;
                        this.form.adultPrice = res.data.adult;
                        this.form.childPrice = res.data.child;
                        this.form.studentPrice = res.data.student;
                        this.form.discount = res.data.discount;
                        this.form.unitPrice = res.data.unitPrice;
                        this.form.totalPrice = res.data.totalPrice;
                        this.form.dealPrice = res.data.dealPrice;
                        this.form.gst = res.data.gst;
                        this.form.promoCode = res.data.promoCode;
                        this.form.promoValue = res.data.promoValue;
                        this.form.promoReason = res.data.promoReason;
                    } else if (res.code == 4001) {
                        this.active = 3;
                        this.error(this.$t('price.error.missPrice'));
                    } else if (res.code == 1) {
                        this.active = 3;
                        if (typeof res.info == 'string') {
                            this.error(res.info);
                        } else if (typeof res.info == 'object') {
                            this.error(Object.values(res.info).join('<br/>'));
                        }
                    }
                })
                .catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        toSubmitOrder() {
            let bookTimes = [];
            this.tableData.forEach(item => {
                if (item.selected) {
                    bookTimes.push(item.time);
                }
            });
            this.form.sessionNumber = bookTimes.length;

            $http.post(host + '/book/queryPayment', {
                bookTimes: bookTimes,
                adult: this.form.adult,
                child: this.form.child,
                student: this.form.student,
                promoCode: this.form.promoCode,
                roomId: this.form.roomId,
                email: this.form.email,
            }).then(res => {
                if (res.code == 0) {
                    if (this.form.promoCode && this.form.promoCode != res.data.promoCode) {
                        this.error(this.$t('price.error.promoCode'));
                    }

                    this.form.timeScope = res.data.timeScope;
                    this.form.adultPrice = res.data.adult;
                    this.form.childPrice = res.data.child;
                    this.form.studentPrice = res.data.student;
                    this.form.discount = res.data.discount;
                    this.form.unitPrice = res.data.unitPrice;
                    this.form.totalPrice = res.data.totalPrice;
                    this.form.dealPrice = res.data.dealPrice;
                    this.form.gst = res.data.gst;
                    this.form.promoCode = res.data.promoCode;
                    this.form.promoValue = res.data.promoValue;
                    this.form.promoReason = res.data.promoReason;

                    setTimeout(() => {
                        this.active = 5;
                    }, 2000);
                } else if (res.code == 4001) {
                    this.active = 3;
                    this.error(this.$t('price.error.missPrice'));
                } else if (res.code == 1) {
                    this.active = 3;
                    if (typeof res.info == 'string') {
                        this.error(res.info);
                    } else if (typeof res.info == 'object') {
                        this.error(Object.values(res.info).join('<br/>'));
                    }
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });
        },

        // 提交预约订单，付款
        submitOrder() {
            this.bookId = '';
            let bookTimes = [];
            this.tableData.forEach(item => {
                if (item.selected) {
                    bookTimes.push(item.time);
                }
            });
            this.form.bookTimes = bookTimes;

            $http
                .post(host + '/book/saveOrder', this.form)
                .then(res => {
                    if (res.code == 0) {
                        // 跳转付款页面
                        this.bookId = res.data.bookId;
                        this.sessionId = res.data.sessionId;
                        this.paymentUrl = res.data.href;
                        window.location.href = this.paymentUrl;
                    } else if (res.code == 4001) {
                        this.active = 2;
                        this.error(this.$t('price.error.missBookTime'));
                    } else if (res.code == 4002) {
                        this.active = 2;
                        this.error(this.$t('price.error.timeContinuous'));
                    } else if (res.code == 4003) {
                        this.active = 2;
                        this.error(this.$t('price.error.timeOccupied'));
                    } else if (res.code == 4004) {
                        this.active = 3;
                        this.error(this.$t('price.error.lackAdult'));
                    } else if (res.code == 4005) {
                        this.active = 3;
                        this.error(this.$t('price.error.limitTotal'));
                    } else if (res.code == 4006) {
                        this.active = 3;
                        this.error(this.$t('price.error.limitWeekend'));
                    } else if (res.code == 4007) {
                        this.active = 3;
                        this.error(this.$t('price.error.limitWeekday'));
                    } else {
                        if (typeof res.info == 'string') {
                            this.error(res.info);
                        } else if (typeof res.info == 'object') {
                            this.error(Object.values(res.info).join('<br/>'));
                        }
                    }
                })
                .catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        /*************** 显示预约费用 ***************/
    }
};
</script>

<style>
.el-button.is-active,
.el-button:active {
    background: #e0efc8;
    border-color: #e0efc8;
    color: black;
}

.el-button:focus,
.el-button:hover {
    background: #e0efc8;
    border-color: #e0efc8;
    color: black;
}

.el-calendar-table .el-calendar-day {
    height: 50px;
}

/*隐藏下个月的日期*/
.el-calendar-table:not(.is-range) td.next {
    color: #282828;
}

/*隐藏上个月的日期*/
.el-calendar-table:not(.is-range) td.prev {
    color: #282828;
}

.el-table {
    color: white;
    background-color: black;
}

.el-table__expanded-cell {
    background-color: black;
}

.el-table th,
.el-table tr {
    background-color: black;
    height: 60px;
}

.el-table__body tr:hover>td {
    background-color: transparent !important;
}

.el-checkbox-button .el-checkbox-button__inner {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
    background-color: black;
    border-radius: 7px;
    color: white;
}

.el-checkbox-button.is-checked .el-checkbox-button__inner {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
    background-color: #e0efc8;
    border-radius: 7px;
    border-color: #e0efc8;
    color: black;
}

.el-calendar-day {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-calendar-table td.is-selected {
    color: black;
    background: #e0efc8;
}

.el-calendar-table .el-calendar-day:hover {
    color: black;
    background: #e0efc8;
}

.el-calendar__body {
    padding: 0;
}

.el-form-item__label {
    color: white;
    text-align: left;
    float: none;
    word-break: break-word;
}

.el-dialog__body {
    padding-left: 5px;
    padding-right: 5px;
}

.popperClass {
    inset: 120px auto auto 26px !important;
}
</style>

<style scoped>
.page {
    /* display: flex; */
    /* justify-content: center; */
    height: calc(100% - 20px);
    padding: 20px 20px 0px 20px;
}

.store_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #e0efc8;
    border-radius: 5px;
    padding: 20px 20px 0px 20px;
    margin-top: 20px;
}

.store_suject {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.store_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.room_list {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
}

.room_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 80vw;
}

.room_image_no {
    width: 80vw;
    height: 240px;
}

.room_image {
    width: 80vw;
}

.room_remark {
    margin-top: 10px;
    width: 80vw;
    font-size: 14px;
    white-space: pre-wrap;
}

.room_select {
    margin-top: 10px;
    width: 120px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

/* .room_title {
    font-weight: 700;
    font-size: 16px;
    flex: 0 0 auto;
}

.room_select {
    margin-left: 10px;
    flex: 1 1 auto;
} */

.calendar_room {
    font-weight: 700;
    margin-bottom: 20px;
}

.calendar_title {
    font-weight: 700;
    font-size: 16px;
    margin-left: 12px;
    flex: 0 0 auto;
}

.calendar_content_space {
    margin-bottom: 0px;
}

.table_button {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
}

.table_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.button_bar {
    margin-top: 15px;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
}

.button_next {
    width: 45%;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.button_prev {
    width: 45%;
    height: 35px;
    background-color: black;
    border-radius: 7px;
    border-width: 3px;
    border-color: #e0efc8;
    font-weight: 700;
    font-size: 15px;
    color: white;
}

.button_verify {
    width: 80px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.div_center {
    display: flex;
    justify-content: center;
}

.otherHeight {
    height: v-bind(otherHeight);
    overflow: scroll;
}

.people_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
}

.people_item {
    height: 75px;
}

.input_number {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.button_number {
    width: 39px;
    height: 32px;
    background-color: #e0efc8;
    border-radius: 7px;
    border-width: 3px;
    border-color: #e0efc8;
    font-weight: 700;
    font-size: 15px;
    color: black;
    font-size: 18px;
}

.name_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
}

.name_item {
    height: 70px;
}

.must_field {
    color: var(--el-color-danger);
    margin-right: 4px;
}

.overflow {
    width: 100%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.price_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
}

.price_booking_title_top {
    line-height: 15px;
    background-color: #e0efc8;
    color: black;
    padding: 10px 10px 5px 10px;
    font-size: 16px;
}

.price_booking_title {
    line-height: 15px;
    background-color: #e0efc8;
    color: black;
    padding: 5px 10px 5px 10px;
    font-size: 16px;
}

.price_booking_title_bottom {
    line-height: 15px;
    background-color: #e0efc8;
    color: black;
    padding: 5px 10px 10px 10px;
    font-size: 16px;
}

.price_detail {
    font-size: 16px;
    line-height: 40px;
    color: white;
}

.price_promo {
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
    color: white;
}

.price_content {
    height: 60px;
    color: white;
}

.price_total {
    height: 80px;
    color: white;
}

.price_total_title {
    color: white;
    font-size: 20px;
}

.thank_button {
    width: 60px;
    height: 60px;
}

.thank_info_1 {
    font-size: 26px;
    font-weight: 700;
}

.thank_info_2 {
    margin-top: 30px;
    font-size: 15px;
    font-weight: 100;
}

.info_bar {
    padding: 10px 15px 10px 15px;
    height: 40px;
    background: #E0EFC8;
    border-radius: 10px;
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
}
</style>
